.hero-container {
  height: 100vh;
  width: 100vw;
  background-color: var(--secondary-color);
}
.hero-text-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center; /* Adjust as needed */
    height: 100vh;
    max-width: 40vw;
    margin-left: 10vw;
    margin-right: 10vw;
    background-color: var(--secondary-color);

}

.hero-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  color: var(--primary-color); /* Text color */
  margin: 0px; /* Resets margin for the container */
  padding: 0px; /* Resets padding for the container */
}

.hero-title h1 {
  font-size: 8vw;
  margin: 0px; /* Resets both top and bottom margins */
  line-height: 1; /* Adjust line height as needed */
  font-family: var(--font-family-title);
}


  .hero-container p {
    font-size: 1em;
    margin-top: 5vh;
    font-family: var(--font-family-body);
  }


  @media (max-width: 480px) {

    .hero-container {
      height: 75vh;
    }
    .hero-text-container {
      max-width: 100vw;
      height: 75vh;
      align-items: center;
    }
    .hero-title h1 {
      font-size: 16vw;
      text-align: center;
    }
    .hero-container p {
      text-align: left;
      font-size: 0.8em;
      padding-left: 5vw;
      padding-right: 5vw;
    }


  }