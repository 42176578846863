.images-container{
    height: 100vh;
    background-color: var(--primary-color);
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center; /* Adjust as needed */
    color: var(--secondary-color);
    width: 100vw;
    position: relative;
    

}

.images-title{

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 0; /* Remove top margin */
    margin-bottom: 0;
    padding-top: 0; /* Remove top padding */
    padding-bottom: 10vh;
}
      
      .images-title h1 {
        font-size: clamp(4vw, 8vw, 12vw);
        margin: 0px; /* Resets both top and bottom margins */
        font-family: var(--font-family-title);
        margin-left: 10vw;
        margin-right: 10vw;
      }
      
      
        .images-container p {
          font-size: clamp(0.5vw, 1vw, 2vw);
          margin-top: 0px;
          margin-left: auto;
          margin-right: auto;
          text-align: center;
          font-family: var(--font-family-body);

            max-width: 30vw;
            margin-left: 10vw;
            margin-right: 10vw;

        }

.large-image {
    position: absolute;
    top: -10vh; /* Adjust as needed */
    left: 20%;
    transform: translateX(-50%);
    width: 50%; /* Control the size of the image */
    height: auto; /* Maintain aspect ratio */

    z-index: 10;
 }

 @media (max-width: 480px) {

    .images-container {
      height: 65vh;
      justify-content:end;
    }

    .images-title h1 {
      font-size: 10vw;
      text-align: center;
    }
    .images-container p {
      text-align: right;
      font-size: 0.5em;
      max-width: 100vw;
    }
    .large-image{
        left: 40%;
        width: 80%;
        top:-10vh;
    }


  }