.guidance-container{
    background-color: var(--secondary-color);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center; /* Adjust as needed */
    color: var(--primary-color);
    width: 100vw;
    position: relative;
    padding: 0%;
    top: -1vh;
    

}

.guidance-text-container{
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100vw;
    padding: 0%;
    margin: 0%;
    position: relative;

}

.guidance-title{

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 0; /* Remove top margin */
    margin-bottom: 0;
    padding-top: 0; /* Remove top padding */
}
      
      .guidance-title h1 {
        font-size: clamp(2vw, 4vw, 8vw);
        margin: 0px; /* Resets both top and bottom margins */
        font-family: var(--font-family-title);
        margin-left: 10vw;
        margin-right: 10vw;
      }
      
      
        .guidance-container p {
          font-size: clamp(0.5vw, 1vw, 2vw);
          margin-top: 0px;
          margin-left: auto;
          margin-right: auto;
          padding: 0%;
          text-align: left;
          font-family: var(--font-family-body);

            max-width: 40vw;
            margin-left: 10vw;
            margin-right: 10vw;

        }

.top-triangle {
    width: 100vw;
  height: 33vh;
  background-color: var(--primary-color); /* Your choice of color */
  clip-path: polygon(0 0, 100% 0, 100% 100%);
}

.bottom-triangle {
    width: 100vw;
  height: 33vh;
  background-color: var(--primary-color); /* Your choice of color */
  clip-path: polygon(100% 0, 100% 100%, 0 100%);
}

@media (max-width: 480px) {

    .guidance-container {
      height: 50vh;
    }

    .guidance-title h1 {
      margin-right: 2vw;
    }
    .guidance-container p {

      text-align: left;
      font-size: 0.5em;
      max-width: 50vw;
      left: 1%;
      position: absolute;
    }

    .top-triangle{
        height:50vh;
        position: relative;
    }
    .bottom-triangle{
        height:50vh;
    }


  }