.spacing-container{
    height: 150vh;
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center; /* Adjust as needed */
    color: var(--secondary-color);
    width: 100vw;

}

.spacing-title{

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0; /* Remove top margin */
    margin-bottom: 0;
    padding-top: 0; /* Remove top padding */
    padding-bottom: 10vh;
}
      
      .spacing-title h1 {
        font-size: clamp(4vw, 8vw, 12vw);
        margin: 0px; /* Resets both top and bottom margins */
        font-family: var(--font-family-title);
      }
      
      
        .spacing-container p {
          font-size: clamp(0.5vw, 1vw, 2vw);
          margin-top: 0px;
          margin-left: 30vw;
          margin-right: 30vw;
          text-align: center;
          font-family: var(--font-family-body);

        }


        @media (max-width: 480px) {

            .spacing-container {
              height: 100vh;
            }
            .spacing-text-container {
              max-width: 100vw;
              height: 100vh;
              align-items: center;
            }
            .spacing-title h1 {
              font-size: 10vw;
              text-align: center;
            }
            .spacing-container p {
              text-align: center;
              font-size: 0.5em;
            }
        
        
          }