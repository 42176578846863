/* Example: src/global.css */
@import url('https://fonts.googleapis.com/css2?family=Rubik+Mono+One:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap');

:root {
    --primary-color: #000000;
    --primary-color-hover: rgba(0, 0, 0, 0.8);
    --secondary-color: #fff;
    --font-family-title: 'Rubik Mono One';
    --font-family-body: 'Space Mono', monospace;
    --padding: 10px;
  }
  
  body {
    background-color: var(--secondary-color);
    overflow-x: hidden;
    position: relative;
  }
  