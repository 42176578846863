footer {
    position: relative;
    left: 0;
    bottom: 0;
    width: 100vw;
    height: 10vh;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    font-family: var(--font-family-body);
    font-size: 1em;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content:space-around; 
    text-align: center;

}

footer a {
    text-decoration: underline;
    color: var(--primary-color);
    font-size: 1em;
}

@media (max-width: 480px) {

    footer{
        flex-direction: column;
        font-size: smaller;
    }
    
}